import { MainPageDataType, MatterType, TagType } from "@/helpers/types";
import { GetServerSideProps, NextPage } from "next";
import { getMainPageData } from "@/helpers/getInstance";
import { usePlatform } from "@/helpers/platformContext";
import MattersList from "@/components/MattersList";
import InfScroll from "@/components/InfScroll";
import Loading from "@/components/Loading";
import useMatters from "@/helpers/useMatters";
import { serverApi } from "@/helpers/api";
import { useDevice } from "@/helpers/deviceContext";
import dynamic from "next/dynamic";
import Banner from "@/components/Banner";
import useToplineTransparenty from "@/helpers/useToplineTransparenty";
import EditButton from "@/components/EditButton";
import { SlugEnum } from "@/helpers/slugEnums";
import withDateToken from "@/helpers/withDateToken";

const MobileStory = dynamic(() => import("@/components/IndexPage/MobileStory"));
const DayPictureMobile = dynamic(
  () => import("@/components/IndexPage/DayPictureMobile")
);
const DayPictureDesktop = dynamic(
  () => import("@/components/IndexPage/DayPictureDesktop")
);
const MobileBottomTabs = dynamic(() => import("@/components/MobileBottomTabs"));

const endpoint = "main_page/matters";
type PropsType = MainPageDataType & {
  initialMatters: MatterType[];
};

const IndexPage: NextPage<PropsType> = ({
  day_picture,
  stories,
  initialMatters,
  day_picture_type,
}) => {
  const { isMobile } = useDevice();
  const { live_config, h1_title_on_main_page, settings_custom } = usePlatform();
  const secondStory = stories ? stories[1]?.story : undefined;
  let firstItem: MatterType | TagType | undefined = day_picture[0]?.matter?.path
    ? day_picture[0]?.matter
    : undefined;
  if (stories && stories[0]?.story) firstItem = stories[0].story;
  if (live_config && (settings_custom.live_mainpage ?? true)) {
    firstItem = live_config;
  }

  const otherItems = day_picture
    ?.filter((el) => el.matter?.path && el.matter?.path !== firstItem?.path)
    .map((el) => el.matter)
    .slice(0, 5);

  const [{ matters, loading }, getMatters] = useMatters({
    initialMatters,
    endpoint,
  });

  useToplineTransparenty(!!day_picture && !!day_picture[0]?.matter?.path); // картины дня может не быть при ошибках на стороне api
  return (
    <>
      {isMobile && firstItem ? (
        <>
          <MobileBottomTabs />
          <DayPictureMobile
            firstItem={firstItem}
            otherItems={otherItems}
            extraPaddingBottom={!!secondStory}
          />
        </>
      ) : (
        firstItem && (
          <DayPictureDesktop
            firstItem={firstItem}
            otherItems={otherItems}
            secondStory={secondStory}
            dayPictureType={day_picture_type}
          />
        )
      )}
      <div className="container">
        {isMobile && secondStory && <MobileStory item={secondStory} />}
      </div>
      <Banner className="my-4" slug={SlugEnum.mainpage_story_after} />
      <Banner
        className="max-w-[826px] mx-auto"
        slug={SlugEnum.mainpage_story_after_2}
      />
      <div className="container">
        <h1 className={"text-lg font-bold mb-[19px] lg:font-serif lg:text-[35px] lg:mb-[38px] max-w-[826px] mx-auto" + (isMobile ? (secondStory ? " mt-[10px]" : " mt-[30px]") : "")}>
          {withDateToken(h1_title_on_main_page)}
        </h1>
        <MattersList
          matters={matters}
          bannerSlug={SlugEnum.mainpage_feed}
          additionalBannerSlug={SlugEnum.mainpage_feed_3}
          showPeriod={false}
          initialMattersLength={
            initialMatters?.length ? initialMatters.length : 0
          }
        />
        <InfScroll onLoad={getMatters} loading={loading} />
        {loading && <Loading />}
        <EditButton url={"/admin/publishers/homepage"} />
      </div>
    </>
  );
};

export default IndexPage;

export const getServerSideProps: GetServerSideProps<PropsType> = async ({
  req,
}) => {
  const results = await Promise.all([
    getMainPageData(req),
    serverApi<MatterType[]>(endpoint, { req }, []),
  ]);

  return "notFound" in results[0] || "notFound" in results[1]
    ? { notFound: true }
    : {
      props: {
        ...results[0].mainPageData,
        initialMatters: results[1].data,
      },
    };
};
